import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, useParams } from "react-router-dom";
import AuthService from '../services/AuthService'
import Navbar from "../components/navbar/Navbar";
import Members from "../pages/members/Members";
import Footer from "../components/footer/Footer";
import "../App.css";
import Testimonials from "../components/aboutus/testimonials/Testimonials";
import Aboutus from "../components/aboutus/Aboutus";
import How from "../components/aboutus/how/How";
import Who from "../components/aboutus/who/Who";
import WhoMelissa from "../components/aboutus/who/WhoMelissa";
import WhoSara from "../components/aboutus/who/WhoSara";
import WhoCathy from "../components/aboutus/who/WhoCathy";
import WhoJan from "../components/aboutus/who/WhoJan";
import Meaning from "../components/aboutus/meaning/Meaning";
import ResetPassword from "../pages/signin/ResetPassword";
import ForgotPassword from "../pages/signin/ForgotPassword";
import JoinUs from "../pages/joinUs/JoinUs";
import Home from "../pages/home/Home";
import Letters from "../pages/letters/Letters";
import SingleNews from "../pages/news/SingleNews";
import BulkNews from "../pages/news/BulkNews";
import Verification from "../pages/joinUs/Verification";
import ContactUs from "../pages/contactUs/ContactUs";
import Donate from "../pages/donate/Donate";
import MultiStepForm from "../pages/scholarship/MultiStepForm";
import Signup from "../pages/signup/Signup";
import Signin from "../pages/signin/Signin";
import Scholarship from "../pages/scholarship/Scholarship";
import WomenInTech from "../pages/womenInTech/WomenInTech";
import PsychologicalSupport from "../pages/psychologicalSupport/PsychologicalSupport";
import Awareness from "../pages/humanRightsAwareness/Awareness";
import ScientificResearch from "../pages/research/ScientificResearch";
import OneTimePassword from "../pages/signin/OneTimePassword";
import Success from "../pages/scholarship/Success";
import ScholarshipVerification from '../pages/scholarship/ScholarshipVerification'
import AnnualReports from '../components/aboutus/annualReports/AnnualReports'


function AppRouter() {
    const [ currentUser, setCurrentUser ] = useState( undefined );

    useEffect( () =>
    {
        const user = AuthService.getCurrentUser();
        if ( user ) {
            setCurrentUser( user );
        }
    }, [] );

    return (
        <Router>
            <div className="App">
                 <Navbar />
                <Switch>
                    <Route path="/" component={Home} exact /> 
                    <Route path="/join" component={JoinUs} exact />
                    <Route path="/testimonials" component={Testimonials} exact />
                    <Route path="/how" component={How} exact />
                    <Route path="/who-melissa" component={WhoMelissa} exact />
                    <Route path="/who-sara" component={WhoSara} exact />
                    <Route path="/who-cathy" component={WhoCathy} exact />
                    <Route path="/who-jan" component={WhoJan} exact />
                    <Route path="/who" component={Who} exact />
                    <Route path="/meaning-of-bt" component={Meaning} exact />
                    <Route path="/about-us" component={Aboutus} exact />
                    <Route path="/annual-reports" component={AnnualReports} exact />
                    <Route path="/letters" component={Letters} exact />
                    <Route path="/news/:id" component={SingleNews} exact />
                    <Route path="/news" component={BulkNews} exact />
                    <Route path="/scholarship" component={ Scholarship } exact />

                    {/* 
                    <Route path="/scholarship/form" component={ MultiStepForm } exact />
                    <Route path="/scholarship/register" component={ Signup } exact />
                    <Route path="/scholarship/signin" component={ Signin } exact />
                    <Route path="/scholarship/forgot-password" component={ ForgotPassword } exact />
                    <Route path="/account/passwordReset" component={ ResetPassword } />
                    <Route path="/scholarship/otp" component={ OneTimePassword } exact />
                    <Route path="/scholarship/success" component={ Success } exact />
                    <Route path="/account/accountEmailVerify" component={ ScholarshipVerification } />
                     */}
                    <Route path="/women-in-tech" component={WomenInTech} />
                    <Route path="/psychological-support" component={PsychologicalSupport} />
                    <Route path="/awareness" component={Awareness} />
                    <Route path="/research" component={ScientificResearch} />
                    <Route path="/join/membershipEmailVerify" component={Verification} />
                    <Route path="/contact" component={ContactUs} exact />
                    <Route path="/donate" component={Donate} exact />
                    <Route path="/members" component={Members} exact />
                </Switch>
                <Footer /> 
            </div>
        </Router>
    );
}

export default AppRouter;
