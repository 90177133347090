import React, { useState, useEffect } from 'react'
import Aos from 'aos';
import Grid from '@material-ui/core/Grid';
import CardMedia from "@material-ui/core/CardMedia";
import ScholarshipImage from '../../assets/images/statue-of-liberty-1210001_1920.jpg';
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles( ( theme ) => ( {
    topImageContainer: {
        overflow: 'hidden',
    },
    media: {
        position: "relative",
        paddingBottom: "38.25%", // 16:9
        backgroundSize: "cover"
    },
    explanationBlock: {
        display: 'flex',
        paddingTop: '2rem',
        '@media screen and (max-width: 900px)': {
            flexDirection: 'column'
        },
    },
    explanation: {
        height: '100%',
        lineHeight: '2.5rem',
        flex: 1,
        padding: '1rem',
        color: "#fff",
        textShadow: '0px 0.5px 5px black',
        fontWeight: 200,
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 20,
        '@media screen and (max-width: 1024px)': {
            fontSize: 20,
            lineHeight: '2rem',

        },
        '@media screen and (max-width: 700px)': {
            fontSize: 20,
            lineHeight: '2rem',

        },
    },
    explanationMiddle: {
        height: '100%',
        lineHeight: '2.5rem',
        flex: 1,
        padding: '1rem',
        color: "#fff",
        textShadow: '0px 0.5px 5px black',
        fontWeight: 200,
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 25,
        textAlign:'center',
        '@media screen and (max-width: 1024px)': {
            fontSize: 24,
            lineHeight: '2rem',

        },
        '@media screen and (max-width: 700px)': {
            fontSize: 24,
            lineHeight: '2rem',

        },
    },
    icon: {
        fontSize: '3rem',
        margin: '1rem',
        color: '#fff',
        textShadow: '0px 1px 3px black',

    },

    iconSmall: {
        fontSize: '2rem',
        margin: '1rem',
        color: '#fff',
        textShadow: '0px 1px 3px black',

    },
    apply: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginTop: '1rem',
        '@media screen and (max-width: 1024px)': {
            flexDirection: 'column',
        },
    },
    button: {
        position: "relative",
        margin: '1rem 1rem',
        padding: '1rem',
        width: 'auto',
        height: '3rem',
        fontSize: 'medium',
        // textShadow: '0px 1px 1px grey',
        // fontSize: 'medium',
        background: '#15678D',
        borderStyle:'none',
        color: '#fff',
        textDecoration: 'none',
        alignContent: 'flex-end',
        justifyContent: 'space-between',
        borderRadius: '0.2rem',
        boxShadow: '0 0 15px 10px #fff',
        '&:hover': {
            transition: '0.3s ease-in-out',
            cursor: 'pointer',
            textShadow: 'none',
            backgroundColor: '#fff',
            border: '1px solid #fff',
            color: '#34455E',
            boxShadow: '0 0 15px 2px #34455E',

        },
    },
} ) );
const Scholarship = () =>
{

    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );
    const imageUrl = windowWidth >= 650 ? ScholarshipImage : ScholarshipImage;
    const classes = useStyles();
    useEffect( () =>
    {
        Aos.init( { duration: 3000 } );
    }, [] );
    useEffect( () =>
    {
        const handleWindowResize = () =>
        {
            setWindowWidth( window.innerWidth );
        };

        window.addEventListener( 'resize', handleWindowResize );

        return () =>
        {
            window.removeEventListener( 'resize', handleWindowResize );
        }
    }, [] );

    return (
        <div className={ classes.topImageContainer } >
            <Grid
                container
                spacing={ 3 }
                alignItems="center"
                justify="center"
            >
                <Grid item xs={ 12 } >
                    <CardMedia
                        className={ classes.media }
                        style={ { backgroundImage: `url(${ imageUrl })` } }
                        component="h2">
                        <div >
                            <div className={ classes.explanationBlock }>
                                <div data-aos={ ( windowWidth > 1024 ? "zoom-in" : "fade-up" ) } data-aos-delay={ ( windowWidth > 1024 ? "800" : "100" ) } className={ ( windowWidth > 1024 ? classes.icon : classes.iconSmall ) }>
                                    <i className="fas fa-university"></i>
                                </div>
                                <div data-aos={ ( windowWidth > 1024 ? "fade-right" : "fade-up" ) } className={ classes.explanation }>
                                    Blue Tulip Scholarship
                            <br></br>Spring 2021, 15 scholars were granted
                            <br></br>Fall 2021, 17 scholars were granted
                            <br></br>Spring 2022, 21 scholars were granted
                            <br></br>Fall 2022, 26 scholars were granted
                            <br></br>Spring 2023, 29 scholars were granted
                            <br></br>Fall 2023, 27 scholars were granted
                            <br></br>Spring 2023, 24 scholars were granted
                            <br></br>Fall 2024, 48 scholars were granted
                            </div>
                                <br />
                                <br />
                                <div data-aos={ ( windowWidth > 1024 ? "zoom-in" : "fade-up" ) } className={ ( windowWidth > 1024 ? classes.icon : classes.iconSmall ) }>
                                    <i className="fas fa-user-graduate"></i>
                                </div>
                                <div data-aos={(windowWidth > 1024 ? "fade-up" : "fade-up")} className={ classes.explanation }>
                                    {/* Apply for Spring 2025 <br></br>Blue Tulip Scholarship Program! */}
                                    We will continue to support exceptional students in the fulfillment of their potentials.
                                </div>
                                <br />
                                <br />
                                <div data-aos={ ( windowWidth > 1024 ? "zoom-in" : "fade-up" ) } className={ ( windowWidth > 1024 ? classes.icon : classes.iconSmall ) }>
                                    <i className="far fa-envelope"></i>
                                </div>
                                <div data-aos={ ( windowWidth > 1024 ? "fade-left" : "fade-up" ) } className={ classes.explanation }>
                                    If you have any questions about the program, please email us at <br></br><span style={ { color: '#1D58A8', textShadow: '1px 1px 3px white' } }>scholarship@blue-tulip.org</span>
                                </div>
                            </div>
                            <div data-aos="zoom-in" className={ classes.apply }>
                                {/* <a href="/scholarship/register" >
                                <button className={ classes.button}>Click to apply for Scholarship</button>
                                </a> */}
                                <a href="/donate" >
                                    <button className={ classes.button }> Donate for Scholarship</button>
                                </a>
                            </div>
                        </div>
                    </CardMedia>
                </Grid>
            </Grid>
        </div>
    )
}

export default Scholarship;